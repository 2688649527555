import React from 'react'
import BackgroundSlider from 'gatsby-image-background-slider'
import {useStaticQuery, graphql} from 'gatsby'

const Layout = ({ children }) => (
  <>
    <main>{children}</main>
    <BackgroundSlider 
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
            nodes {
              relativePath
              childImageSharp {
                fluid (maxWidth: 4000, quality: 100){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `)}
      initDelay={1} // delay before the first transition (if left at 0, the first image will be skipped initially)
      transition={3} // transition duration between images
      duration={5} // how long an image is shown
      // specify images to include (and their order) according to `relativePath`
        style={{height: "750",backgroundSize: "cover"}}
      // pass down standard element props
      className="background_img"        
    > 
     
    </BackgroundSlider>
  </>
)  
export default Layout;