import React from 'react'
import { Tabs, Tab, Row, Col, Form,Button, Carousel} from 'react-bootstrap'
import "../styles/ticketForm.css"
// import FloatingLabels from '../components/floatingLabel'
import img1 from '../images/Rectangle 65 2.png'
import img2 from '../images/Rectangle 65.png'
import img3 from '../images/Rectangle.png'
import underline from '../images/underline.png'


const TicketForm = () => {
    return (
        <div>
        
            <section className="section">
                <div>
                        <Carousel style={{position:"absolute", zIndex:"0"}} fade>
                            <Carousel.Item>
                                <img className="tab1" src={img1} alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="tab1" src={img2} alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="tab1" src={img3} alt="Third slide" />
                            </Carousel.Item>
                        </Carousel>
                </div>
                <Tabs style={{position:"absolute"}}
                 defaultActiveKey="flight"
                 transition={false}
                 id="noanim-tab-example"
                 className="tabs">
                
                <Tab eventKey="flight" title="Flight" >
                    
                    <div className="tabss">
                        

                    <div className="tab1Div" >
                        <h1 className="heading1">Get Booking Assistance</h1>
                    </div>
                    <div className="" style={{display:"flex", justifyContent:"center"}}>
                        <div className="rectangle1">    
                            <div>
                            <Form action="https://getform.io/f/63fe7438-2f10-4542-9745-8484082cca5c" method="POST">
                                <Row className="row1" style={{display:"flex", justifyContent:"center", alignItems:"start"}}>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">FROM</Form.Label>
                                        <Form.Control className="formInput1" type="select" placeholder="Enter Origin City" name="From" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">TO</Form.Label>
                                        <Form.Control className="formInput2" type="select" placeholder="Enter Destination" name="To" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="dob">
                                        <Form.Label className="label">DATE</Form.Label>
                                        <Form.Control className="formInput3" type="date" placeholder="Select Date" name="Date" />
                                        
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">Passengers</Form.Label>
                                        {/* <Form.Control type="number" className="formInput4" type="select" placeholder="1" name="from" /> */}
                                        </Form.Group>
                                        <div className="allPassengers" style={{display:"flex", justifyContent:"start"}}>
                                            <div >
                                                <Form.Group className="Group1"  as={Col} controlId="">
                                                <Form.Label className="labelA1">Adult</Form.Label>
                                                <Form.Control className="formInputA1" type="select" placeholder="1" name="Adult" />
                                                </Form.Group>
                                            </div>
                                            <div>
                                                <Form.Group className="Group1"  as={Col} controlId="">
                                                <Form.Label className="labelA1">Child</Form.Label>
                                                <Form.Control className="formInputA1" type="select" placeholder="1" name="Child" />
                                                </Form.Group>
                                            </div>
                                            <div>
                                                <Form.Group className="Group1"  as={Col} controlId="">
                                                <Form.Label className="labelA1">Infant</Form.Label>
                                                <Form.Control className="formInputA1" type="select" placeholder="1" name="Infant" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                       
                                       
                                    </Col>
                                </Row>
                                
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <img className="underLine" src={underline} alt="" />    
                                </div>
                                <Row>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="labelContact">CONTACT</Form.Label>
                                        <Form.Control className="formInputContact" type="text" placeholder="Phone or Email" name="Phone/Email" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Button className="button1"  style={{}} variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                    </div>
                </Tab>
                <Tab eventKey="hotel" title="Hotel" className="tab2">
                
                    <div className="tabss">

                    <div className="tab1Div" >
                        <h1 className="heading1">Get Booking Assistance</h1>
                    </div>
                    <div className="" style={{display:"flex", justifyContent:"center"}}>
                        <div className="rectangle2">
                            <div>
                            <Form action="https://getform.io/f/63fe7438-2f10-4542-9745-8484082cca5c" method="POST">
                                <Row className="row1" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">WHERE YOU GO?</Form.Label>
                                        <Form.Control className="formInput1" type="select" placeholder="Enter a region" name="WhereYouGo?" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">CHECK IN</Form.Label>
                                        <Form.Control className="formInput2" type="date" placeholder="Select Date" name="Check In" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">CHECK OUT</Form.Label>
                                        <Form.Control className="formInput3" type="date" placeholder="Select Date" name="Check Out" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="label">Guests</Form.Label>
                                        {/* <Form.Control type="number" className="formInput4" type="select" placeholder="1" name="from" /> */}
                                        </Form.Group>
                                        <div className="allPassengers" style={{display:"flex", justifyContent:"start"}}>
                                            <div >
                                                <Form.Group className="Group1"  as={Col} controlId="">
                                                <Form.Label className="labelA1">Adult</Form.Label>
                                                <Form.Control className="formInputA1" type="select" placeholder="1" name="Adult" />
                                                </Form.Group>
                                            </div>
                                            <div>
                                                <Form.Group className="Group1"  as={Col} controlId="">
                                                <Form.Label className="labelA1">Child</Form.Label>
                                                <Form.Control className="formInputA1" type="select" placeholder="1" name="Child" />
                                                </Form.Group>
                                            </div>
                                            <div>
                                                <Form.Group className="Group1"  as={Col} controlId="">
                                                <Form.Label className="labelA1">Infant</Form.Label>
                                                <Form.Control className="formInputA1" type="select" placeholder="1" name="Infant" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                       
                                       
                                    </Col>
                                </Row>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <img className="underLine" src={underline} alt="" />    
                                </div>
                                
                                <Row>
                                    <Col md={3} xs={6}>
                                        <Form.Group as={Col} controlId="">
                                        <Form.Label className="labelContact">CONTACT</Form.Label>
                                        <Form.Control className="formInputContact" type="text" placeholder="Phone or Email" name="Phone/Email" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Button className="button1"  style={{}} variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                    </div>
                </Tab>
                </Tabs>
            </section>
        </div>
    )
}

export default TicketForm
