
import React from "react"
import { graphql, } from 'gatsby'
import '../styles/index.css'
import Layout from '../components/layout'
// import Form from '../components/flightForm'
// import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col} from 'react-bootstrap'
import BackgroundSlider from '../components/BackgroundSlider'
// import Slider from '../components/infiniteLoopSlider'
// import { Button } from "bootstrap"
// import emailjs from 'emailjs-com';
import TicketForm from "../components/ticketForm"


// function sendEmail(e) {
//   e.preventDefault();

//   emailjs.sendForm('gmail', 'template_b9t930r', e.target, 'user_IGsduVyeKexJxUrEEoLYY')
//     .then((result) => {
//         console.log(result.text);
//     }, (error) => {
//         console.log(error.text);
//     });
//     e.target.reset()
// }



const Home = ({data}) => {
  return (
    
    <Layout>
      
        <div class="first_section">

          <BackgroundSlider />

            <div class="background_overlay">
              <Container>
                
                <Row >
                    
                    <Col  className="fi_section_content_area">
                       <div class="vertical_line"></div>
                       <h1 class="title" style={{fontWeight:"bold"}}>the best partner <br></br>for Your Unforgettable Journey</h1>
                       <h4 class="sub_title">Creating Memories to Cherish For A Lifetime</h4>                                    
                    </Col>
                
                </Row>
              </Container>
              </div>
              {/* <div className="btm_svg sticky-bottom" ></div> */}
          
        </div>  
        <div className="all_section">
        <Container>
          <Row className="padding_sec">
            <h2 className="abt_head d-none d-sm-block" style={{color:" #008FCC"}}>Ausflug <span style={{color: "#35B250"}}>Holidays</span></h2>
            <p className="d-none d-sm-block">With our dedicated service and utmost customer satisfaction, Ausflug Holidays is the best for your travel needs by creating a strong presence in the tours and travel industry. The phoenix in medical tourism. We are a link that travels through the history of health tourism, combining mental, physical, and functional factors to nurture the human spirit. Our program is to expedite their services to you in collaboration with 40 leading Indian healthcare companies. Also, local guides for language convenience at your service in every state of your travel in India and abroad. We value you in all means and are committed to serving you in the best possible way.
           Health care is essential for human salvation. Our goal and vision are to be one of the leading travel and tourism service providers in India promoting eco-friendly tourism innovating ourselves to deliver the best in industry and to mold a healthy nation in order to achieve holistic humanity. We are on the verge of it.</p>
          
            
            {/* <p>The phoenix in medical tourism.We are a link that travels through the history of health tourism, combining mental, physical and functional factors to nurture the human spirit.Our program is to expedite their services to you in collaboration with 40 leading Indian healthcare companies.</p> */}
          </Row>
          
            
         {/* <Row className="padding_sec">
            
            <Slider />  
          </Row> */}

          {/* <Row className="second_section">
          
            <Col sm className="img_col">
              <Img className="boat_img" fluid={probs.data.img.childImageSharp.fluid} /> 
            </Col>
              
            <Col sm>
              <h3 class="txt_why_india">
                  <span className="blk" style={{color:"#008FCC"}}>why</span> 
                  <span className="orange" style={{color:"#35B250"}}> india ?</span>
                </h3>
                <ul>
                  <li class="txt_features">COST EFFECTIVE</li>
                  <li class="txt_features">MODERN TECHNOLOGY</li>
                  <li class="txt_features">HIGHLY QUALIFIED DOCTORS</li>
                  <li class="txt_features">QUALITY OF HEALTH CARE</li>
                  <li class="txt_features">NO LANGUAGE BARRIER</li>
                  <li class="txt_features">GREAT CUSTOMER CARE</li>
                  <li class="txt_features">RICH IN CULTURE</li>
                  <li class="txt_features">SAFETY</li>
                  <li class="txt_features">EASY TO OBTAIN VISA</li>
                  <li class="txt_features">OFFER TREATMENTS LIKE UNANI,AYURVEDA SIDHA</li>


                  </ul> 
            </Col>

                 
          </Row> */}



              

                
        <TicketForm/>



        </Container>

                <Container className="whiteBackground" style={{marginBottom:"70px",marginTop:"110px"}}>
                
                
                  <div >
                  <div className="title">
                    <h1 className="trending_title" style={{marginTop:"15px",color:"black",marginLeft:"-30px"}}>Trending Packages</h1>
                  </div>
                  
                  <div className="mainSection">
                  {data.allContentfulTrendingPackages.edges.map(edge => (
                    <div><a href={`${ edge.node.destinationLink}`}>
                    <div className="column">
                  
                  
                      <div className="destination">
                        <img className="destinationImage" src={edge.node.placeImage.file.url} alt="" /> 
                      
                        <div className="price1">
                          <h1 className="rs"><span className="from">From</span> Rs. {edge.node.price}</h1>
                        </div>
                        
                        <div className="about">
                          <h6 className="days" style={{color:"#fff"}}>{edge.node.days}</h6>
                          <h2 className="days" style={{marginTop:"-10px"}}>{edge.node.place}</h2>
                        </div>
                      
                      </div>
                    </div></a>
                    
                    
                    
                    
                    
                    
                    
                    
                    </div>
                  ))}
                    
                  </div>
                  </div>
                
               </Container>


            </div>        

          <div class="section_3">
               {/* <div class="top_svg"></div>  */}
               <BackgroundImage
                className="second_background"
                fluid={data.backgroundimage.childImageSharp.fluid}
               >
                 <Container style={{overflow:"hidden"}}>
                 <div class="white_overlay" style={{backgroundColor:"transparent"}}>
                   
                 <h5 class="txt_services">Our Specialities</h5>
                          {/*services ist row*/} 

                          
                          <Row className="">
                              
                          
                                  <Col xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                      
                                      <img src="../../ic_round-support-agent.svg" alt="support agent ico" className="ico"></img>
                                      <p className="weight-600">ONLINE SUPPORT</p>
                                      
                                      
                                  </Col>

                                  <Col xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                      <img src="../../jam_clock.svg" alt="clock ico" className="ico"></img>
                                      <p className="weight-600">24/7 HELP</p>
                                      
                                      
                                  </Col>

                                  <Col  xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                      <img src="../../mdi_message-reply-text.svg" alt="message-reply-text ico" className="ico"></img>
                                      <p className="weight-600">QUICK REPLY</p>
                                    
                                      
                                  </Col>


                                  <Col xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                        
                                        <img src="../../Vector.svg" alt="shakehand icon" className="ico"></img>
                                        <p className="weight-600">TRAVEL ASSISTANCE</p>
                                        
                                        
                                    </Col>

                                    <Col xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                        <img src="../../carbon_hotel.svg" alt="accomodation icon" className="ico"></img>
                                        <p className="weight-600">ACCOMODATION</p>
                                        
                                        
                                        
                                    </Col>

                                    {/*services 2nd row*/}

                                    <Col xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                        <img src="../../ri_translate-2.svg" alt="translate ico" className="ico"></img>
                                        <p className="weight-600">TRANSLATOR</p>
                                      
                                        
                                    </Col>


                                    <Col  xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                  
                                        <img src="../../codicon_feedback.svg" alt="feedback icon" className="ico"></img>
                                        <p className="weight-600">FEEDBACK SYSTEMS</p>
                                  
                                  
                                    </Col>

                                    <Col  xl={2} lg={2} md={4} sm={6} xs={6} className="icons">
                                        <img src="../../carbon_car.svg" alt="pickup icon" className="ico"></img>
                                        <p className="weight-600">PICKUP/DROP</p>
                                  
                                  
                                    </Col>

                                    <Col  xl={2} lg={2} md={4} sm={6} xs={12} className="icons">
                                        <img src="../../bx_bx-handicap.svg" alt="handicap icon" className="ico"></img>
                                        <p className="weight-600">SPECIAL ASSISTANCE</p>
                                 
                                  
                                    </Col>
                                
                          
                                 
                          
                                    
                                  
                                  
                            {/*services 3rd row*/}

                          
                              
                            </Row>
                            

                  </div>
                 </Container>
                 
               </BackgroundImage>
            </div> 


            
            <div class="btm_svg_2"></div>
            <Container>
                 {/* <Row className="justify-content-center">
                   <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h4 class="head_testmonial">What our Clients have to say</h4>
                  </Col>

                    <Col xl={2} lg={2} md={0} sm={0} xs={0} ></Col>

                        <Col xl={10} lg={10} md={12} sm={12} xs={12} >
                            <img class="left_quote" src="../../bx_bxs-quote-left.svg" alt="left quote"></img>
                        </Col>

                        <Col xl={4} lg={4} md={2} sm={2} xs={2} ></Col>
                            <Col xl={4} lg={4} md={8} sm={8} xs={8} >
                            <p class="text_testimonial">Ausflug Holidays arranged us the Perfect stay at a nice ayurvedic facility.<br></br><span class="txt_client">john doe</span></p>
                            </Col> 
                        <Col xl={4} lg={4} md={2} sm={2} xs={2} ></Col>    
                        

                        <Col xl={6} lg={6} md={10} sm={10} xs={10} ></Col>

                            <Col xl={2} lg={2} md={2} sm={2} xs={2} >
                            <img class="right_quote" src="../../bx_bxs-quote-right.svg" alt="right quote"></img><br></br>
                            </Col>


                    
                 </Row> */}
                 
              </Container>
              {/* <div className="all_section"></div> */}
              {/* </div>      */}
        
    </Layout>
  )
}

export default Home;

export const pageQuery =graphql`
query {
 

          img: file(relativePath: {eq: "indianflag.png"}) {
            childImageSharp {
              fluid(quality:50)
              {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize 
              }
        }
        } 


        backgroundimage: file(relativePath: {eq: "mountain1.webp"}) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50)
             {
              ...GatsbyImageSharpFluid 
            }
          }
          }

          allContentfulTrendingPackages(sort: {fields: place}) {
            edges {
              node {
                place
                days
                placeImage {
                  file {
                    url
                  }
                }
                price
                destinationLink
              }
            }
          }
              
}
`



// Set-ExecutionPolicy -Scope Process -ExecutionPolicy Bypass
